@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&family=Space+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
}

$surveyPrimary: #6a3494;

button {
  cursor: pointer;
}
.app-message {
  color: $surveyPrimary !important;
}

button:focus {
  outline: none !important;
}
input:disabled {
  background-color: #d8dbe0;
  opacity: 1;
  cursor: not-allowed;
}
// variables
$sidebar: #28303f;
$sidebar-selected: #0d59cf;
body {
  font-family: "Lato", sans-serif;
  background: #fff;
}
:focus {
  outline: none;
  box-shadow: inherit !important;
}
.logo img {
  width: 200px;
}
.dropdown button:hover {
  background: none;
}
.dropdown button {
  background: none;
  border: none;
}

ul.c-sidebar-nav li i {
  margin: 0 10px 0 0px;
}
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background: none;
  border: none;
}
ul.c-sidebar-nav li.active {
  background: $sidebar-selected;
}
ul.c-sidebar-nav li a {
  cursor: pointer;
  text-decoration: none;
  display: block;
  padding: 13px 25px;
}
ul.c-sidebar-nav li {
  margin-bottom: 5px;
  cursor: pointer;
}
.sidebar {
  img {
    width: 85px !important;
    margin: 0 20px !important;
  }
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background: none;
  border: none;
}
.dropdown.open button {
  margin-bottom: -5px;
}
.dropdown-menu > li > a {
  font-size: 14px;
  line-height: 0;
  padding: 7px 10px 8px 11px;
}
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: none !important;
  border-color: none !important;
  box-shadow: inherit;
}
li.c-sidebar-nav-item a {
  padding: 15px 20px;
}
.c-main {
  padding-top: 3rem !important;
}
.fade.show {
  opacity: 9 !important;
}
.logo {
  width: 100%;
  padding: 15px 10px;
  box-shadow: 0px 5px 4px #040b18;
  margin-bottom: 10px;
}
.logo img {
  width: 200px;
  margin: 0 10px 0 0px;
}
.logo label {
  font-size: 16px;
  position: relative;
  top: 3px;
  font-weight: 400;
}
.breadcrumb > li + li:before {
  padding: 1px 5px !important;
}
.breadcrumb-item.active {
  color: rgb(68, 68, 68);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #8a93a2;
}
li.breadcrumb-item a {
  color: rgb(31, 36, 41);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.c-sidebar-nav li a {
  // color: rgb(255, 255, 255);
  color: #fff;
  font-size: 14px;

  font-weight: 500;
}
.logo label strong {
  font-weight: 900;
}
.logo p {
  float: left;
  width: 80%;
  margin: 0;
  text-align: center;
}
.logo span {
  font-size: 18px;
  float: right;
}

.img-preview {
  max-width: 100px;
  height: 100px;
}
.img-preview-table {
  max-width: 50px;
  height: 50px;
}
.hand {
  cursor: pointer;
  padding: 8px;
  top: 50px;
  right: 0;
}
.black-text {
  color: #000 !important;
}

.header {
  width: 100%;
  padding: 0 20px;
  border-bottom: 3px solid rgb(255, 255, 255);
  img {
    width: 80px;
  }
  .header-admin span {
    background: $sidebar-selected;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 12px;
    border-radius: 3px;
  }
  .admin-img {
    float: right;
  }
  .toggle-menu i {
    color: rgb(96, 96, 96);
    font-size: 18px;
    margin: 19px 0 0 10px;
  }
  .toggle-menu {
    float: right;
  }
  .header-inner {
    display: inline-block;
    float: right;
    margin: 10px 0;
  }
  .header-admin {
    margin: 8px 0 0 0;
  }
  ul.dropdown-menu {
    margin-top: 8px !important;
  }
  .header-admin label {
    color: rgb(68, 68, 68);
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    margin: 0;
  }
}
.login.recover {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  button.pink-btn img {
    left: 8%;
  }
}
.login {
  padding: 27px 0px;
  /*position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);*/
  background: #fff;
  /*&.sign-in-logo {
    padding-top: 80px;
  }*/
  /*.main-img {
    position: relative;
  }*/
  img {
    width: 95%;
    // position: absolute;
    // top: 8%;
    margin: auto;
  }
  .logo {
    // margin-bottom: 50px;
    box-shadow: inherit;
  }
  .logo img {
    width: auto;
    position: inherit;
  }
  .form-check input {
    float: left;
    margin: 0 10px 0 0;
    width: auto;
  }
  .form-check label {
    font-size: 14px;
    color: rgb(120, 120, 120);
    font-weight: 400;
    position: relative;
    top: -4px;
  }
  h3 {
    color: rgb(35, 35, 35);
    font-size: 29px;
    font-weight: 900;
    margin-bottom: 15px;
  }
  & h5 {
    font-size: 1.7rem;
    margin-bottom: 3rem;
    color: #777;
    font-weight: normal;
  }
  input {
    // width: 100%;
    border: none;
    padding-bottom: 5px;
    box-shadow: inherit;
    border-bottom: 1px solid #ccc;
    // margin-bottom: 35px;
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 5px;
    border-radius: 0;
  }
  .error-block{
    padding: 0.3rem 0.8rem;
  }
  ::-webkit-input-placeholder {
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400 !important;
  }
  ::-moz-placeholder {
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400;
  }
  :-ms-input-placeholder {
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400;
  }
  :-moz-placeholder {
    color: rgb(120, 120, 120);
    font-size: 14px;
    font-weight: 400;
  }
  .form-check {
    padding: 0 !important;
    text-align: left;
  }
  label.hand.forgot_pw a {
    color: rgb(120, 120, 120);
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    top: 5px;
  }
  .submit_row {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  button.pink-btn {
    width: 190px;
    height: 55px;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-style: normal;
    background: $sidebar-selected;
    font-weight: 500;
    border: 1px solid $sidebar-selected;
    position: relative;
    & img {
      position: absolute;
      top: 45%;
      left: 20%;
      width: 25px;
      transform: translateY(-30%);
    }
    &:focus {
      outline: none;
    }
  }
  button.pink-btn:disabled {
    background: $sidebar-selected;
    border: 1px solid $sidebar-selected;
    cursor: not-allowed;
  }
  & .not-a-member-field {
    font-size: 1.5rem;
    margin: auto;
    display: flex;
    justify-content: center;
    & label {
      font-weight: normal;
      color: #777;
      margin-right: 2px;
    }
  }
  & .account {
    margin: auto;
  }
  .already-account {
    // font-size: 1.3rem;
    font-weight: normal;
    margin: auto;
  }
}

.css-yk16xz-control {
  border-radius: 0;
  font-size: 14px;
  border-color: #d8dbe0;
}
.css-1pahdxg-control,
.css-26l3qy-menu {
  font-size: 14px;
}
.css-g1d714-ValueContainer {
  margin: 0;
  padding: 0 0 0 15px;
}

.white-container {
  background: #fff;
  padding: 15px 25px;
  display: inline-block;
  width: 100%;
  position: relative;
  .users-number span {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .users-number {
    float: right;
    margin: 5px 0 0 20px;
  }
  .users-number label {
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    text-align: center;
    margin-bottom: -5px;
  }
  h2 {
    span.id-number {
      float: right;
      color: #777;
      font-size: 24px;
      position: absolute;
      right: 25px;
      top: 15px;
      text-align: right;
      width: 230px;
    }
    color: rgb(35, 35, 35);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 35px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }
  select {
    width: 100%;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    padding: 0 15px;
    color: #7a7a7a;
  }
  form p {
    float: left;
    width: 45%;
    margin: 0 45px 30px 0px;
  }
  .form-group {
    margin-bottom: 2rem;
  }
  .stack-inputs {
    p {
      width: 100%;
    }
  }
  input {
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 0 15px;
  }
  textarea {
    // width: 100%;
    font-size: 14px;
    padding: 10px 15px;
  }
  button {
    width: 200px;
    border-radius: 8px;
    margin: 0 20px 0 0px;
    height: 48px;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    border: none;
  }
  .save {
    background: $sidebar-selected;
    width: 150px;
  }
  button.save:disabled {
    background: $sidebar-selected;
    border: 1px solid $sidebar-selected;
    cursor: not-allowed;
  }
  .cancel {
    background: #444444;
    width: 150px;
  }
  .upload-file-label {
    margin-bottom: 2.7rem;
    background: #fdfdfd;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 300px;
    height: 40px;
    padding: 8px 15px;
    text-transform: none;
  }
  label {
    width: 100%;
    color: #7a7a7a;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
  }
  .row.buttons {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .user-upload {
    margin-bottom: 45px;
    position: relative;
    width: 200px;
  }
  .user-upload img {
    width: 250px;
  }
  .user-upload span img {
    width: 30px;
    position: absolute;
    bottom: 25px;
    right: 43px;
  }
  .user-upload {
    margin-bottom: 45px;
    position: relative;
  }
  .buttons i {
    color: #a6a6a6;
    font-size: 20px;
    cursor: pointer;
    padding-top: 10px;
  }
  .user-upload img {
    width: 150px;
  }
  ::-webkit-input-placeholder {
    color: #777;
    font-size: 14px;
    font-weight: 700;
  }
  ::-moz-placeholder {
    color: #777;
    font-size: 14px;
    font-weight: 700;
  }
  :-ms-input-placeholder {
    color: #777;
    font-size: 14px;
    font-weight: 700;
  }
  :-moz-placeholder {
    color: #777;
    font-size: 14px;
    font-weight: 700;
  }
  .user-upload-inner {
    height: 150px;
    width: 150px;
    border-radius: 50%;

    overflow: hidden;
  }
}

.sub-categories {
  margin-top: 50px;
  h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

.green-pill {
  background: #72bb52;
}
.gray-pill {
  background: #5f5f5f;
}
.olive-pill {
  background-color: #a08e22;
}
.red-pill {
  background-color: #fa4f40;
}
.blue-pill {
  background-color: #0db1d2;
}

// BADGES
.badges {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  width: 120px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
}
.table.data {
  // TABLES

  thead tr th {
    font-size: 16px;
    color: #000;
    padding-bottom: 10px;
  }
  td {
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-top: 15px;
  }
  &.billing {
    td {
      border: none;
    }
    tr td:last-child {
      float: right;
    }
    tr.last-row {
      border-top: 1px solid #ddd;
      td {
        font-weight: 600;
      }
    }
  }
}

.head-button-input-grp {
  margin-top: 20px;
  label {
    border-bottom: 1px solid #ccc;
    input {
      border: none;
      padding: 1px;
      margin: 0;
      width: 90%;
    }
  }
}

.order-details {
  padding-bottom: 50px;
  h3 {
    font-weight: 600;
    font-size: 16px;
  }
  span.content {
    font-size: 12px;
    font-weight: 600;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px !important;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $sidebar-selected;
}

input:focus + .slider {
  box-shadow: 0 0 1px $sidebar-selected;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.search-section input {
  border: none;
  border-bottom: 2px solid #e9e9e9;
  padding: 0;
}
.search-bar i {
  position: absolute;
  font-size: 14px;
  top: 12px;
  right: 25px;
  color: #e9e9e9;
}
.search-section .search-bar {
  position: relative;
}
.search-section {
  margin-bottom: 50px;
  margin-top: 25px;
}
.search-section select {
  border: 2px solid #e9e9e9;
  padding: 0 5px;
  border-radius: 5px;
}
.search-section .date input {
  border: 2px solid #e9e9e9;
  padding: 0 15px;
  border-radius: 5px;
}

.controller-outer {
  position: relative;
  margin-bottom: 1.5rem;
}
.error-block {
  // position: absolute;
  // top: 43px;
  color: red;
  font-weight: 700;
  // padding: 0.3rem 2rem;
}
.err-block {
  position: absolute;
  color: red;
  font-weight: 700;
}
.faded-text {
  color: #777 !important;
}
.order-detail {
  h4 {
    color: rgb(35, 35, 35);
    font-size: 24px;
    font-weight: 700;
  }
  .order-detail-header span.badges {
    background: rgb(77, 189, 116);
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: bold;
    padding: 3px 20px;
    position: relative;
    top: -3px;
    border-radius: 5px;
  }
  .order-detail-header span {
    color: rgb(68, 68, 68);
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-style: normal;
    margin: 0 0 0 20px;
    font-weight: 900;
  }
  h5 {
    color: rgb(35, 35, 35);
    font-size: 15px;
    margin: 10px 0 0 0;
    font-weight: bold;
    text-transform: uppercase;
  }
  .clients-info {
    border-top: 2px solid #ccc;
    padding-top: 10px;
    margin-top: 10px;
  }
  .clients-info img {
    width: 45px;
  }
  .clients-info .col-md-4:last-child {
    border: none;
  }
  .clients-info.no-border {
    border: none;
    margin-top: 30px;
  }
  h6 {
    font-size: 14px;
    line-height: 22px;
  }
  .client-detail span {
    color: #444444;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 2px;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    padding-bottom: 16px;
  }
  .client-detail p {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .client-detail {
    margin-top: 20px;
  }
  .clients-info .col-md-4 {
    border-right: 1px solid rgb(68, 68, 68);
  }
}

.no-img-selected {
  text-align: center;
  padding: 55px 0;
  font-size: 15px;
}

.inplace-confirm {
  position: relative;
  .main-body {
    text-align: center;
    padding: 10px 20px;
    color: #000;
    font-size: 14px;
    // background: red;
    border: 2px solid gainsboro;
    position: absolute;
    right: 7%;
    bottom: -20px;
    box-shadow: 5px 10px gainsboro;
    background: #fff;
    button {
      width: 40px;
      border-radius: 15px;
      margin-left: 15px;
      margin-top: 10px;
      height: 20px;
      font-size: 12px;
      font-weight: 400;
      background-color: #949494;
      color: #fff;
      border: none;
    }
  }
}
ul.custom_pagination {
  margin: 10px 15px 25px 0;
  padding: 0;

  li {
    cursor: pointer;
    list-style: none;
    display: inline;
    border: 1px solid #9c9c9c;
    padding: 6px 15px;
    &.active {
      background: $sidebar-selected;
      a {
        color: #fff !important;
      }
    }
    a {
      text-decoration: none;
      color: #9c9c9c;
      font-size: 12px;
    }
  }
}

/***data tables search***/
#custom_datatable_info {
  display: none;
  padding-bottom: 5px;
}
#custom_datatable_paginate a {
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  color: #c8c6cb;
  font-weight: 400;
  padding: 8px 15px;
  border-right: 1px solid #c8c6cb;
}
#custom_datatable_paginate {
  float: right;
  border: 1px solid #ccc;
  padding: 6px 0;
  margin-top: 5px;
}
.paginate_button.current {
  background: $sidebar-selected;
  color: #fff !important;
  font-weight: 600 !important;
}
#custom_datatable_filter {
  width: 250px;
  float: right;
}
#custom_datatable_filter input {
  font-weight: 300;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0;
  color: #000;
  padding: 0 30px 0 0px;
}
#custom_datatable_filter label {
  color: #fff;
}
#custom_datatable_filter {
  width: 250px;
  float: right;
  margin-bottom: 25px;
}
i.fas.fa-search.search-top.category-tab {
  right: 120px;
}
i.fas.fa-search.search-top {
  position: absolute;
  right: 35px;
  font-size: 14px;
  top: 40px;
}
.add-btn button {
  // background: $surveyPrimary;
  // width: 178px;
  // height: 54px;
  // font-family: "Lato", sans-serif;
  // font-style: normal;
  // font-weight: normal;
  // font-size: 15px;
  // padding: 8px 40px;
  // cursor: pointer;
  // font-weight: 600;
  // border-radius: 300px;
  // top: 20px;
  width: 91px;
  height: 26px;
  left: 1587px;
  top: 257px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #000000;
  background: #fff;
  white-space: nowrap;
  &:hover {
    color: #1ee08d;
  }
}
.add-btn {
  display: flex;
  justify-content: flex-end;
  .points {
    h2 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 56px;
      color: $surveyPrimary;
      margin: 0;
      padding: 0;
      border: none;
    }
    span {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 4px;
    }
  }
}
.outer-wrap{
  display: flex;
  justify-content: flex-end;
  .add-btn{
    & > *{
      margin: 0 10px;
      &:last-child{
        margin-right: 0;
      }
    }
    button{
      width: 100%;
    }
    span{
      font-weight: bold;
      font-size: 16px;
    }
  }
}
/***end***/

.c-sidebar {
  color: #fff;
  background: $sidebar;
}
.signups-data-tables {
  table-layout: fixed;
  width: 100%;
}

ul.c-sidebar-nav li a img {
  width: 22px;
  margin: 0 0.4rem;
}

// ul.c-sidebar-nav li:not(.active) {
//   color: #fff;
//   & a {
//     color: #fff
//   }
// }

.sidebar img {
  width: 30px !important;
}
.ant-pagination-item-active {
  background: $sidebar;
  border: $sidebar;
}
.antd-custom-pagination {
  & .ant-pagination-item-active {
    background: $sidebar;
    border: $sidebar;

    & a {
      color: #fff;
    }
  }
}
.antd-custom-pagination-survey {
  a {
    color: $surveyPrimary;
  }
}
th.drag-visible {
  white-space: nowrap;
  padding-right: 2rem;
}

.antd-custom-pagination {
  & li.ant-pagination-prev,
  & li.ant-pagination-next,
  & li.ant-pagination-item {
    border: 0.1px solid;
    padding: 0 1rem;
    margin-right: 0;
    border-radius: 0px;
    border-color: #aaaaaa;
  }
  & .ant-pagination-item-active {
    background: #28303f;
    border: #28303f;

    & a,
    & a:hover {
      color: #fff !important;
    }
  }
  & .ant-pagination-item a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
}
.custom-table-loader .ant-spin-dot-item {
  background: #28303f;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: #000 !important;
}
li.active.breadcrumb-item {
  padding-top: 0.5rem;
}
.ant-table-thead > tr > th {
  background: #fff !important;
}
td.custom-table-data {
  a {
    color: $surveyPrimary;
  }
  color: rgba(0, 0, 0, 0.6) !important;
  cursor: pointer;
}
.textarea-control .error-block {
  position: relative;
  top: 10px;
}
td.custom-table-data-survey {
  padding-right: 4rem !important;
}
.survey-details-header {
  button {
    background: $surveyPrimary !important;
  }
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  // border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  align-items: center;
  & > div {
    display: flex;
    gap: 0.4rem;
    font-family: "Lato", sans-serif;
  }
  & h2 {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;

    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;

    color: $surveyPrimary;
  }
  & h1 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 53px;
  }
  & button {
    position: relative;
    height: 45px;
    width: 170px;
    background: $sidebar-selected;
  }
  img {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
  }
  .survey-details-heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    span {
      width: 176px;
      height: 67px;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      line-height: 59px;
      color: $surveyPrimary;
    }
  }
}
p.item-id-right {
  text-align: end;
  font-size: 2.5rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 59px;

  color: $surveyPrimary;
}

.height-auto {
  max-height: initial !important;
  height: auto;
  position: relative;
}
.height-auto:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 95%;
  background: #777;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
}

.height-0 {
  max-height: 0;
  overflow: hidden;
  transition: 1s all ease-in-out;
}
#uploadFile {
  display: none;
}

span.uploadFile-wrapper {
  border: 1px solid #ccc;
  padding: 1.1rem 0;
  border-radius: 50px;
  /* max-width: 100%; */
  box-sizing: border-box;
}
span.uploadFile-wrapper label {
  cursor: inherit;
  &:hover span:first-child {
    background: $surveyPrimary;
    color: #fff;
  }
}
span.uploadFile-wrapper label span:first-child {
  border: 1px solid;
  padding: 0.5rem;
  /* background: #FFFFFF; */
  margin-left: 1rem;
  border-radius: 50px;
}

span.uploadFile-wrapper label span i {
  margin-left: 0.3rem;
}
span.image-file-name {
  display: inline-block;
  margin: 0 1rem;
}

.col-md-6.same-line-controller {
  display: flex;
  max-width: 100%;
  flex: 0 0 100%;
  div {
    width: 100%;
  }
  .controller-outer:first-child input {
    width: 95%;
  }
  div select {
    width: 100%;
    // height: 30px;
    border: none;
    border-bottom: 1px solid #ccc;
    background: #fff;
    // font-size: 1.5rem;
  }
}
.error{
  color: red;
}

.terms {
  display: flex;
  margin-bottom: 15px;
  label {
    margin: 0;
    // font-size: 1.5rem;
    font-weight: normal;
  }
  input {
    width: auto;
    padding: 0;
    margin-right: 10px;
  }
}
// .owl-nav {
//   display: none
// }
.owl-item {
  float: left;
  display: none;
  &.active {
    display: initial;
  }
}

.owl-carousel {
  overflow: hidden;
}

// utility classes

// for button hover effect
.hover-slow-motion {
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    // background-color: $surveyPrimary59;
    z-index: -1;
    transition: all 0.4s ease-in-out;
    border-radius: 50px;
  }

  &:hover:after {
    width: 115%;
    height: 130%;
  }
}
$scrollBackground: $surveyPrimary;
.scroll-container {
  width: 60px;
  height: 60px;
  color: #fff;
  border-radius: 50%;
  background: $scrollBackground;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: -10px;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 22;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease-in-out;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: lighten($scrollBackground, 10%);
    z-index: 20;
  }
  &:hover:after {
    width: 97%;
    height: 97%;
  }
}

.survey-suboptions {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  label {
    // width: 80px;
    // height: 80px;
    width: 163px;
    .img-trigger {
      width: inherit;
      // height: 80px;
      border: 1px solid;
      border-radius: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        border-radius: 10px;
      }
      i {
        margin: 0;
      }
    }
  }
  i {
    margin-left: 2.3rem;
    cursor: pointer;
    &.fa-plus {
      color: green !important;
    }
    &.fa-times {
      color: red !important;
    }
  }
}
.list-item-survey {
  margin: auto;
  font-size: 1.5rem;
  border: 1px solid $surveyPrimary;
  border-radius: 50px;
  padding: 1.2rem;
  margin: 1.3rem 0;
  cursor: pointer;
  transition: all 0.2s ease-in;
  display: flex;
  i {
    margin-left: auto;
    margin-right: 10px;
    align-self: center;
  }
  &:hover {
    box-shadow: 2px 2px $surveyPrimary;
    padding-left: 2rem;
  }
}
.home-header-divider {
  background: $surveyPrimary;
  height: 1px;
  // margin-bottom: 1.5rem;
}
.selected-survey {
  border: 1px solid $surveyPrimary;
  text-align: center;
  font-size: 16px;
  position: sticky;
  top: 30px;
  padding: 4rem 0;
  border-radius: 30px;
}
.user-home-content {
  font-size: 16px;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  // grid-template-columns: repeat(auto-fill, 258px);
  margin-bottom: 2.8rem;
  h2 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
  }
  .col-md-3-custom {
    max-width: 25rem;
  }
  .survey-card {
    border-radius: 20px;
    box-shadow: 0px 1px 3px #000;
    padding: 0;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
    height: 100%;
    .survey-card-img{
      height: 200px;
      img {
        width: 100%;
        height: 100%;
        padding: 0;
        object-fit: cover;
      }
    }
    .content {
      padding: 0.5rem 1rem;
      position: relative;
      min-height: 170px;
      .earnBtnMain {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0px);
    }
    }
    .survey-name {
      font-size: 17px;
    }
    .survey-points {
      color: $surveyPrimary;
      font-size: 13px;
      font-weight: 700;
    }
    .survey-discover {
      color: #828282;
      font-size: 13px;
    }
    button.earn-button {
      font-size: 15px;
      margin: 1.1rem auto;
      display: block;
      padding: 1rem 3rem;
      border: none;
      border-radius: 50px;
      background: $surveyPrimary;
      color: #fff;
      border: 1px solid $surveyPrimary;
      transition: all 0.3s ease-in;
      &:hover {
        background: #fff;
        color: $surveyPrimary;
      }
    }
  }
}

.survey-details button {
  background: $surveyPrimary;
  padding: 1.2rem;
  border-radius: 50px;
  margin: auto;
  display: block;
  outline: none;
  border: none;
  color: #fff;
  border: 0.5px solid $surveyPrimary;
  transition: all 0.2s ease-in;
  &:hover {
    color: $surveyPrimary;
    background: #fff;
    border: 0.5px solid $surveyPrimary;
  }
}

.survey-details div {
  margin: 1.2rem 0;
}
.survey-details {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  .points span + div {
    margin: 0;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #444444;
  }

  .points span {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 68px;
    color: $surveyPrimary;
    margin: 0;
  }
  .survey-details {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .details {
    text-align: left;
    padding: 1.2rem;
  }
}
.info-timeline {
  font-family: "Lato", sans-serif;
  padding-top: 10rem;
}
.info-timeline-survey {
  display: flex;
  justify-content: center;
  gap: 5.6rem;
  border: 1px solid;
  width: 50vw;
  margin: auto;
  border-radius: 600px;
  background: #fdfdfd;
  padding: 1.2rem;
  border: 1px solid #ebebeb;
}
.info-timeline-progress {
  margin: 4rem auto;
  width: 926px;
  height: 14px;
  border-radius: 200px;
  background: #fafafa;
  .info-inline {
    background: $surveyPrimary;
    width: 0;
    height: 100%;
    position: relative;
    border-radius: 20px;
    &:after {
      content: "";
      position: absolute;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: $surveyPrimary;
      z-index: 99;
      height: 100%;
    }
    &.info-inline-with-animation-before-complete {
      background: $surveyPrimary;
    }
    &.info-inline-with-animation:after {
      // animation: slow-progress 1s ease-in infinite;
    }
  }
}
.question-box {
  border: 1px solid#EBEBEB;
  border-radius: 10px;
  padding: 2rem;
  .question {
    text-align: center;
  }
}

.current-question-detail span:nth-child(2) {
  margin-left: auto;
  color: #828282;
}

.current-question-detail {
  display: flex;
  /* margin: auto; */
}

@keyframes slow-progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
button.submitSurvey {
  margin: 2rem auto;
  display: block;
  padding: 1.2rem 5rem;
  color: #fff;
  border: none;
  transition: all 0.2s ease-in;
  border-radius: 5px;
  background: #3a3a3a;
  height: 54px;
  border-radius: 300px;
  &.survey-starter {
    background: $surveyPrimary !important;
    cursor: pointer;
    border: 1px solid $surveyPrimary;
    transition: all 0.2s ease-in;
    &:hover {
      background: #fff !important;
      color: $surveyPrimary;
    }
  }
  &:not(.survey-starter):hover {
    color: $surveyPrimary;
    background: #fff;
    border: 0.5px solid $surveyPrimary;
  }
}
.account-selection select {
  border: none;
  border-bottom: 1px solid #ccc;
  background: #fff;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.loader {
  font-size: 16px;
  text-align: center;
  div {
    margin: 0 0.5rem;
  }
}

.survey-questions-list {
  display: flex;
  grid-template-columns: 1fr 1fr;
  flex-wrap: nowrap;
  gap: 2.5rem;
  margin: 1.3rem 0;
  justify-content: center;
  label,
  img {
    margin: 0 0.5rem;
    font-weight: normal;
  }
  img {
    vertical-align: top;
    border-radius: 10px;
    width: 80px;
    height: 80px;
    display: inline-block;
    margin: 0.5rem;
  }
}
.off-complete div {
  margin: 1.2rem 0;
}
.spinner-border:not(.btn-loader) {
  border-color: $surveyPrimary;
  border-right-color: transparent;
}
.spinner-border.history-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem auto;
}
.load-more-survey-progressively.text-center button {
  font-size: 16px;
  border: none;
  background: $surveyPrimary;
  color: #fff;
  padding: 1.2rem;
  outline: 1px solid #fff;
  outline-offset: -5px;
  border-radius: 5px;
}

@media only screen and (max-width: 950px) {
  .info-timeline-survey {
    border: none;
  }
}

.user-settings-container {
  font-size: 16px;
  h2 {
    margin: 1.2rem 0;
    font-size: 22px;
    font-weight: 700;
    color: #000;
  }
  a {
    color: #000;
    text-decoration: none;
  }
  .setting-toggler {
    /* border: 1px solid; */
    display: block;
    padding: 2rem;
    margin: auto;
    text-align: center;
  }
  a.current-settings {
    border: 1px solid #ccc;
    padding: 1rem 4.5rem;
    margin: 2rem 0;
    white-space: nowrap;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    &:first-child {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      border-right: none;
    }
    &:last-child {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      padding-right: 3.5rem;
      border-left: none;
    }
    &.active {
      background: $surveyPrimary;
      color: #fff;
    }
  }
  .item-list {
    display: flex;
    gap: 3rem;
    width: 100%;
    .support-items {
      display: flex;
      a {
        border: 1px solid;
        border-radius: 50px;
        padding: 15px;
      }
    }
  }

  .setting-account {
    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    margin: 1.5rem 0;
    padding: 3rem 0;
    .inplace-confirm .main-body {
      z-index: 99;
      transform: translate(20%, 20%);
      background: #fff;
    }
    span.delete-profile {
      background: #e8fcf4;
      width: 40px;
      height: 40px;
      display: inline-block;
      position: relative;
      border-radius: 50%;
      margin: 2rem 0;
      cursor: pointer;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: red;
        cursor: pointer;
      }
    }
    button.question-reset {
      background: #3a3a3a;
      color: #fff;
      padding: 1.2rem 2rem;
      border-radius: 50px;
      border: none;
      margin: 2rem 0;
    }
    color: #000;
    span.profile-img-show {
      cursor: pointer;
      & + span {
        margin-left: 2rem;
        font-size: 2rem;
      }
      width: 140px;
      height: 140px;
      position: relative;
      display: inline-block;
      img {
        width: inherit;
        height: inherit;
        border: 1px solid;
        border-radius: 50%;
      }
      span {
        position: absolute;
        bottom: 10px;
        right: 5px;
        display: inline-block;
        background: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;

        i {
          color: $surveyPrimary;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    button.form-button {
      border: none;
      padding: 1.2rem 3rem;
      border-radius: 50px;
      outline: none;
      color: #fff;
      &.form-button-cancel {
        background: #1ee08d;
        width: 135px;
        font-size: 15px;
        font-weight: 400;
        margin-right: 2rem;
      }
      &.form-button-submit {
        background: $surveyPrimary;
        width: 135px;
        font-size: 15px;
        font-weight: 400;
      }
    }
    .controller-outer {
      padding: 2rem 0;
      input {
        padding: 1.2rem 2rem;
        border-radius: 50px;
        outline: none;
        border: none;
        border: 1px solid #ebebeb;
        width: 80%;
        &:disabled {
          background-color: #d8dbe0;
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
  .setting-account {
    h2 {
      font-size: 17px;
      font-weight: 700;
      margin: 10px 0 15px 0;
    }
    .profile {
      span {
        font-size: 31px;
        font-weight: 700;
      }
    }
  }
}
.referrals-container.container {
  margin-top: 1.8rem;
  font-size: 16px;
  color: #000;
  font-family: "Lato", sans-serif;
  h2 {
    font-size: 1.8rem;
  }
  .referral-innner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 6rem;
      color: $surveyPrimary;
      font-weight: 900;
    }
    .referrals-control {
      width: 100%;
      .row > div {
        margin: auto;
        text-align: center;
        input,
        textarea {
          padding: 2rem;
          margin: 0;
          border-radius: 50px;
          border: none;
          border: 1px solid #ccc;
          width: 100%;
          outline: none;
        }
        textarea {
          margin-bottom: 0 !important;
        }

        &.message-text textarea {
          border-radius: 10px;
          resize: none;
          height: 200px;
        }
      }
      button.send-btn {
        width: 100%;
        background: $surveyPrimary;
        padding: 2rem 0;
        color: #fff;
        border-radius: 50px;
        outline: none;
        border: none;
        border: 1px solid;
        transition: all 0.2s ease-in;
        font-size: 15px;
        font-weight: 400;
        // &:hover {
        //   background: #fff;
        //   color: $surveyPrimary;
        // }
        .spinner-border:not(.btn-loader) {
          border-color: #fff;
          border-right-color: transparent;
        }
      }
      .copy-url-section {
        display: flex;
        justify-content: center;
        padding: 2rem 0;
        margin: 2rem 0;
        gap: 1rem;
        width: 100%;
        margin: 0 auto;
        div {
          display: flex;
          flex-direction: column;
          border-radius: 50px;
          border: 1px solid #ccc;
          padding: 5px 24px;
          width: 100%;
          margin-right: 15px;
          text-align: left;
        }
        button {
          padding: 1rem 3rem;
          border: none;
          background: #3a3a3a;
          color: #fff;
          border-radius: 50px;
          transition: all 0.2s ease-in;
          font-size: 15px;
          &:hover {
            background: $surveyPrimary;
            color: #fff;
          }
        }
        .copyURL {
          .label_text {
            font-size: 12px;
            font-weight: 700;
            color: #aaaaaa;
          }
          .urlLink {
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
      .inviteRemain {
        text-align: end;
        color: #828282 !important;
        font-weight: 400;
        font-size: 15px;
        width: 80%;
        margin: 0 auto;
      }
    }
    .share-link {
      width: 52%;
      text-align: center;
      margin: auto;
      h3 {
        text-align: start;
        padding: 0;
        font-size: 15px;
        font-weight: 700;
      }
      .refer-icon {
        font-size: 15px;
        font-weight: 400;
        &.icon-fb {
          background: #3f5793;
        }
        &.icon-twitter {
          background: #47a2f3;
        }
        &.icon-insta {
          background: #bf1e7b;
        }
        padding: 1.2rem 2rem;
        border-radius: 50px;
        color: #fff;
        transition: all 0.4s ease-in;
        border: 1px solid #fff;
        cursor: pointer;
        margin: 1rem 0;
        &:hover {
          background: #542c73;
          border-color: #000;
        }
        i {
          margin-right: 2px;
        }
      }
    }
  }
}

.bonus-container {
  font-family: "Lato", sans-serif;
  padding: 2rem 0;
  margin: 2rem 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  background: $surveyPrimary;
  color: #fff;
  h2 {
    text-align: center;
    font-size: 31px;
    font-weight: 900;
    margin: 1.2rem 0;
    color: #fff;
  }
  h3 {
    color: #fff;
  }
  p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
  }
  .box {
    display: flex;
    justify-content: center;
    grid-gap: 3rem;
    gap: 0rem;
    align-items: center;
    width: 42%;
    margin: auto;
    border: 1px solid;
    padding: 15px 30px;
    border-radius: 10px;
    border-color: #fff;
    color: #fff;
    h2 {
      font-size: 21px !important;
      font-weight: 900 !important;
      margin-left: 15px;
    }
    .brand-details {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-left: auto;
      span {
        font-size: 15px;
        font-weight: 400;
      }
    }
    .img-holder {
      width: 80px;
      height: 80px;
      display: inline-block;
      border-radius: 50%;
      background: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
      }
    }
  }
  .share-link {
    width: 43%;
    text-align: center;
    margin: auto;
    h3 {
      text-align: start;
      padding: 0;
      font-size: 15px;
      font-weight: 700;
    }
    .refer-icon {
      font-size: 15px;
      font-weight: 700;
      background: #542c73;
      padding: 1.2rem 2rem;
      border-radius: 50px;
      color: #fff;
      cursor: pointer;
      transition: all 0.4s ease-in;
      border: 1px solid #542c73;
      margin: 1rem 0;
      &:hover {
        background: $surveyPrimary;
        border-color: #fff;
      }
      i {
        margin-right: 2px;
      }
    }
  }
  .copy-url-section {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    margin: 2rem 0;
    gap: 1rem;
    .parent-url {
      display: flex;
      flex-direction: column;
      border-radius: 50px;
      padding: 0.5rem 2.7rem;
      text-align: start;
      width: 35%;
      background: #542c73;
      .labelText {
        font-size: 12px;
        font-weight: 700;
      }
      .labelLink {
        font-size: 15px;
        font-weight: 400;
      }
    }
    button {
      padding: 1rem 3rem;
      border: none;
      background: #fff;
      color: #000;
      border-radius: 50px;
      position: relative;
      border: 1px solid #fff;
      transition: all 0.4s ease-in;
      font-size: 15px;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: $surveyPrimary;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .referrals-control form input,
  .referrals-control form textarea {
    width: 100% !important;
  }
  .brand-square-box .brand-img .box {
    .brand-details {
      flex-direction: column;
      margin: auto;
      text-align: center;
    }
  }
  .copy-url-section {
    .parent-url {
      margin: auto;
      width: 80% !important;
    }
    .bonus-container .share-link h3 {
      text-align: center;
    }
    .copyURL {
      width: 70% !important;
    }
    button.copy-button {
      width: 25%;
      font-size: 13px !important;
    }
  }

  .submit_row {
    .signInSubmitBtn {
      text-align: center;
    }
    .pink-btn {
      font-size: 16px !important;
      width: 170px !important;
      height: 50px !important;
      img {
        width: 20px !important;
      }
    }
    .forgot_pw a {
      font-size: 14px;
    }
  }
  .user-signup-container {
    .signup-form {
      width: 100% !important;
      transform: translateY(0) !important;
    }
  }
  .info-timeline {
    .info-timeline-survey {
      width: 100%;
    }
  }
  .user-home-content {
    .content {
      height: 200px;
      position: relative;
      .earnBtnMain {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
    }
  }
  .referrals-container {
    .referral-innner-container {
      h1 {
        font-size: 4rem !important;
      }
      p {
        font-size: 14px;
      }
      .referrals-control {
        .row > div input,
        .row > div textarea {
          padding: 1rem !important;
          font-size: 13px !important;
        }
        .inviteRemain {
          width: 100% !important;
          font-size: 12px !important;
        }
        button.send-btn {
          width: 100% !important;
          padding: 1rem 0 !important;
        }
        .copy-url-section {
          width: 65% !important;
          .copyURL {
            .label_text {
              font-size: 10px;
            }
            .urlLink {
              font-size: 13px;
            }
          }
        }
      }
      .share-link {
        width: 65% !important;
        h3 {
          font-size: 13px !important;
          margin-top: 10px;
        }
        .refer-icon {
          padding: 1.2rem !important;
          font-size: 13px !important ;
        }
      }
    }
  }
  .bonus-container {
    h2 {
      font-size: 28px;
    }
    p {
      font-size: 13px;
      width: 75%;
      margin: 0.9rem auto;
    }
    .box {
      width: 55% !important;
      .img-holder {
        width: 65px;
        height: 65px;
      }
      h2 {
        font-size: 15px !important;
      }
      .brand-details {
        span {
          font-size: 12px;
          text-align: start;
        }
      }
    }
    .copy-url-section {
      width: 55%;
      margin: 0 auto;
      .parent-url {
        .labelText {
          font-size: 10px;
          font-weight: 700;
        }
        .labelLink {
          font-size: 13px;
        }
      }
    }
    .share-link {
      width: 54%;
      h3 {
        text-align: start;
        font-size: 13px;
      }
      .refer-icon {
        font-size: 13px !important;
        padding: 1.2rem !important;
      }
    }
  }
  .user-settings-container {
    .setting-account {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .item-list {
      gap: 1rem;
      .support-items {
        a {
          padding: 11px;
          font-size: 14px;
        }
      }
    }
  }
}

// admin header fix

// .header {
//   border: none;
//   padding: 0;
//   ul {
//     padding: 10px 20px;
//     display: flex;
//     justify-content: flex-end;
//     gap: 2rem;
//     list-style-type: none;
//     margin: 0;
//     background-color: #fff;
//     li.header-brand {
//       margin-right: auto;
//       img {
//         width: 30px;
//         margin: 0 0.7rem;
//       }
//       padding: 1rem;
//       a {
//         font-size: 2rem;
//         font-weight: 700;
//       }
//     }
//     li.active {
//       background: #2d59cf;
//       a {
//         color: #fff;
//       }
//       img {
//         filter: contrast(1) !important;
//       }
//     }
//     li:not(.header-brand) {
//       padding: 1rem;
//       border-radius: 10px;
//       height: 50px;
//       img {
//         width: 30px;
//         filter: contrast(0);
//       }
//     }
//     li .dropdown {
//       .flex-drop {
//         display: flex;
//         flex-direction: column;
//       }
//       font-size: 16px;
//       .dropdown-menu {
//         font-size: 16px;
//       }
//     }
//   }
// }

.custom-table-data-history {
  font-family: "Lato", sans-serif;
  &:last-of-type(th) {
    padding-right: 6rem;
  }
}
th.custom-table-data-history {
  &:last-child {
    // padding-right: 6rem;
  }
}

td.custom-table-data-history {
  &:last-child {
    padding-right: 6rem;
  }
}

.antd-custom-pagination-history li {
  &:first-child {
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
  }
  &:last-child {
    border-top-right-radius: 19px;
    border-bottom-right-radius: 19px;
  }
}

.antd-custom-pagination-survey {
  li {
    &:first-child {
      border-top-left-radius: 19px;
      border-bottom-left-radius: 19px;
    }
    &:last-child {
      border-top-right-radius: 19px;
      border-bottom-right-radius: 19px;
    }
  }
  .ant-pagination-item-active {
    background: $surveyPrimary;
  }
}

.survey-info-container {
  border-bottom: 1px solid #2bbbb4;
  font-size: 16px;
  display: flex;
  gap: 1.8rem;
  // min-height: 50vh;
  .info-content {
    width: 100%;
    padding: 2rem 5.2rem;

    .survey-history {
      h2 {
        padding: 0 1.1rem;
        font-size: 2.4rem;
      }

      // .ant-table-wrapper table > thead > tr > th.custom-table-data {
      //   font-family: "Lato", sans-serif;

      //   &:nth-child(2) {
      //     text-align: center;
      //   }
      //   &:last-child {
      //     border-top-right-radius: 2px;
      //     text-align: right;
      //     padding-right: 6rem;

      //   }
      // }

      & button.view-details {
        padding: 0.4rem 0.7rem;
        color: #fff;
        background: #2bbbb4;
        border: none;

        outline: 1px solid #fff;
        outline-offset: -3px;
        cursor: pointer;
        border-radius: 3px;
      }
      table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        thead tr th {
          &:nth-child(2) {
            text-align: center;
            font-family: "Lato", sans-serif;
          }
          &:last-child {
            border-top-right-radius: 2px;
            font-family: "Lato", sans-serif;
          }
        }
        tbody tr td {
          &:nth-child(2) {
            text-align: center;
          }
          &:nth-child(3) {
            text-align: right;
            padding-right: 6rem;
          }
        }
        a {
          color: $surveyPrimary !important;
        }
      }
    }
  }

  .survey-info-sidebar {
    padding: 0;
    width: 23rem;
    border-right: 1px solid $surveyPrimary;
    /* border-bottom: 2px solid $surveyPrimary; */
    ul {
      list-style-type: none;
      li {
        display: block;
        padding: 0.9rem;
        padding-left: 1.8rem;
        &.active {
          background: #2bbbb4;
          a {
            color: #fff;
          }
        }
        &:not(.active) {
          border: 1px solid;
          border-left: none;
          border-right: none;
        }
        a {
          color: #000;
          text-decoration: none;
          i {
            margin: auto 0.4rem;
            display: inline-block;
          }
        }
      }
    }
  }
}

.survey-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  & ul {
    list-style-type: none;
    & ul li strong {
      margin: 0.5rem;
    }
  }
}
.survey-history .antd-custom-pagination .ant-pagination-item.ant-pagination-item-active {
  background-color: $surveyPrimary !important;
}

.modal-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  .common-modal {
    z-index: 99999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* height: 16rem; */
    /* width: 16rem; */
    border: 1px solid #000;
    padding: 0;
    box-shadow: 2px 2px 4px #000;
    animation: slow-height 0.4s;
    .modal-headers {
      background: #2bbbb4;
      color: #fff;
      text-align: center;
      padding: 0.9rem 0;
    }
    .modal-contents {
      box-shadow: none;
      border: none;
      padding: 2rem 4rem;
      background: #fff;
    }
    .modal-actions {
      background: #fff;
      padding: 0.8rem 0;
      button {
        display: block;
        margin: auto;
        padding: 0.7rem 1rem;
        background: #2bbbb4;
        outline: none;
        color: #fff;
        border: none;
      }
    }
  }
}

@keyframes slow-height {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
.container {
  h2 {
    font-size: 21px;
    font-weight: 700;
  }
}

.survey-header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.8rem;
  font-weight: 700;
  font-size: 17px;
  h2 {
    font-weight: bold;
    font-size: 21px;
  }
  span {
    color: $surveyPrimary;
    font-size: 17px;
    font-weight: bold;
  }
  button {
    border: 1px solid $surveyPrimary;
    background: #fff;
    padding: 1.2rem 2rem;
    border-radius: 50px;
    color: $surveyPrimary;
    font-size: 15px;
    font-weight: 400;
    width: 128px;
    height: 54px;
  }
  /* padding: 0 4rem; */
}

@media only screen and (max-width: 767px) {
  .profile-controls input {
    margin: auto;
    display: block;
  }

  .profile label {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .user-settings-container h2 {
    text-align: left;
  }
  button.form-button {
    margin: auto;
    display: block;
    width: 100%;
    margin-bottom: 0.8rem;
  }
  .user-settings-container .item-list {
    justify-content: center;
  }
  .account {
    text-align: center !important;
    margin-top: 10px !important;
  }
  .signup-content {
    padding: 1rem 2rem !important;
    .signup-man-image {
      img {
        width: 100%;
      }
    }
  }
  .login {
    padding: 30px 0px;
    form {
      text-align: center;
      .form-check {
        text-align: left;
      }
      .terms {
        text-align: left;
      }
    }
  }
  .main-img {
    img {
      display: none;
    }
  }
  .user-signup-container {
    padding: 5rem 0rem !important;
    img {
      display: none;
    }
    .complete {
      margin: 0 auto;
    }
    .signup-form {
      width: 100% !important;
      transform: translateY(0) !important;
      .complete {
        float: unset !important;
      }
    }
  }
  .requestBtn {
    text-align: center;
  }
  // .same-line-controller input[type="date"] {
  //   margin-right: 16px !important;
  // }
  .container {
    .survey-header-info {
      margin-bottom: 30px;
    }
    .user-home-content {
      display: block;
      .survey-card {
        margin-right: 0 !important;
      }
      .info-timeline {
        padding-top: 5rem;
        submitSurvey {
          padding: 1.2rem 3rem;
        }
      }
    }
  }
  .col-md-3-custom {
    max-width: 100% !important;
  }
  .info-timeline-survey {
    gap: 2.6rem !important;
    .survey-starter {
      width: 100%;
      text-align: center;
      font-size: 10px;
    }
  }
  .referrals-container {
    .referral-innner-container {
      .referrals-control {
        .copy-url-section {
          width: 100% !important;
        }
      }
      .share-link {
        width: 100% !important;
      }
    }
  }
  .bonus-container {
    padding: 2rem 6rem;
    p {
      width: 100%;
    }
    .box {
      width: 100% !important;
      .brand-details {
        margin: 0 0 0 auto !important;
      }
    }
    .copy-url-section {
      width: 100%;
    }
    .share-link {
      width: 100%;
    }
  }
  .user-settings-container {
    .current-settings {
      display: block;
      border-radius: 50px;
      margin-top: 5px !important;
      border-left: 1px solid #ccc !important;
    }
    .inplace-confirm {
      text-align: end;
    }
    .setting-account {
      .controller-outer {
        padding: 1rem 0;
      }
      h2 {
        text-align: left;
      }
      .form-button-cancel,
      .form-button-submit {
        display: inline;
        margin: 0 auto;
      }
      input {
        width: 100% !important;
      }
      .item-list {
        display: block;
      }
      .support-items {
        a {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .formBtns {
        margin-top: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .container {
    h2 {
      font-size: 16px;
    }
    .survey-header-info {
      display: block !important;
      span {
        font-size: 14px;
      }
      .viewAllBtn {
        text-align: right;
        button {
          font-size: 12px;
          width: 90px;
          height: unset;
          padding: 0.5rem;
        }
      }
    }
    .user-home-content {
      .survey-card {
        margin-bottom: 2.5rem;
      }
    }
  }
  .referrals-container {
    h2 {
      font-size: 1rem !important;
    }
    .referral-innner-container {
      h1 {
        font-size: 2rem !important;
      }
      p {
        font-size: 12px;
      }
      .referrals-control {
        .row > div input,
        .row > div textarea {
          margin: 1rem 0 !important;
        }
        .copy-url-section {
          display: block !important;
          .copyURL {
            width: 100% !important;
          }
          .copy-button {
            width: 100%;
            margin-top: 12px;
          }
        }
      }
    }
  }
  .bonus-container {
    padding: 2rem;
    h2 {
      font-size: 20px;
      margin: 1.2rem 0 0 0;
    }
    p {
      width: 100%;
      font-size: 11px;
    }
    .box {
      width: 90% !important;
      padding: 15px;
      h2 {
        margin: 0 0 0 8px;
        text-align: left;
        font-size: 12px !important;
      }
    }
    .img-holder {
      width: 55px;
      height: 55px;
    }
    .copy-url-section {
      display: block;
      width: 90%;
      .parent-url {
        width: 100% !important;
      }
      .copy-button {
        width: 100%;
        margin-top: 12px;
      }
    }
    .share-link {
      width: 100%;
    }
  }
  .user-settings-container {
    .current-settings {
      padding: 0.5rem 4.5rem !important;
      margin: 1rem 0 !important;
    }
    .setting-account {
      margin: 0;
      padding: 0;
      .profile-img-show {
        width: 100px !important;
        height: 100px !important;
        margin-bottom: 10px;
      }
      .controller-outer input {
        padding: 0.7rem 1.2rem;
      }
      .form-button {
        padding: 1rem 3rem;
      }
    }
    .earning-box {
      padding: 15px;
      h3 {
        font-size: 30px;
        margin: 0;
      }
      h1 {
        font-size: 40px;
      }
      p {
        margin-bottom: 25px;
        font-size: 12px;
      }
      button {
        width: 100%;
      }
      a {
        font-size: 12px;
      }
    }
  }
  .survey-history {
    .surbey-history-tools {
      margin-top: 35px;
      .select-label {
        font-size: 10px;
      }
      h2 {
        font-size: 40px;
      }
      span {
        font-size: 14px;
      }
      select {
        width: 135px;
        padding: 1rem 3.5rem 0 1rem;
      }
    }
  }
}

// user-signup selections

.signup-content {
  font-size: 16px;
  text-align: center;
  color: #000;
  margin: 1rem 0;
  padding: 1rem 10rem;
  p {
    font-family: "Lato", sans-serif;
    color: #3a3a3a;
  }
  h4 {
    font-size: 2rem;
  }
  h2 {
    text-align: center;
    font-size: 3.2rem;
    font-weight: 700;
    color: $surveyPrimary;
    font-family: "Lato", sans-serif;
    margin: 1.1rem 0;
  }
  .col-md-6 {
    border: 1px solid #ebebeb;
    padding: 1rem;
    margin: 0.7rem 0;
  }

  .signup-man-image img {
    display: block;
    margin: auto;
    height: 215px;
  }

  button.signup-btn {
    font-family: "Lato", sans-serif;
    padding: 1.2rem 8rem;
    border: none;
    color: #fff;
    border-radius: 50px;
    transition: all 0.4s ease-in;
    &.client-signup-btn {
      background: $surveyPrimary;
      &:hover {
        background: #1ee08d;
      }
    }
    &.user-signup-btn {
      background: #1ee08d;
      &:hover {
        background: $surveyPrimary;
      }
    }
  }
}

.container.user-signup-container {
  font-size: 16px;
  border-radius: 10px;
  z-index: 20;
  background: #fff;
  padding: 10rem 0;
  .error-block {
    font-size: 1rem;
  }
  // .col-md-6 {
  //   // position: absolute;
  //   // top: 50%;
  //   // transform: translateY(-50%);
  //   &:first-child {
  //     left: 0;
  //   }
  //   &:nth-child(2) {
  //     right: 0;
  //   }
  // }
  img {
    max-width: 100%;
  }
  .signup-form {
    border-radius: 10px;

    margin: auto;
    font-size: 16px !important;
    padding: 0 1.8rem;
    // box-shadow: 1px 1px 4px #000;
    // border: 1px solid #EBEBEB;
    font-family: "Lato", sans-serif;
    width: 460px; /*
    height: 515px;*/
    transform: translateY(60px);
    .signup-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    h3 {
      font-family: "Lato", sans-serif;
      font-size: 2.8rem;
      color: $surveyPrimary;
      margin: 0.4rem 0;
      font-weight: bold;
    }
    h5 {
      // font-size: 1.2rem;
      // margin: 1.8rem 0;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 30px;
      /* or 23px */

      color: #3a3a3a;
    }
    .controller-outer input {
      padding: 2.1rem 1.8rem 1.8rem 1.8rem;
      font-size: 1.3rem;
      border-radius: 10px;
      height: 54px;
      &::placeholder {
        font-size: 1.2rem;
      }
    }
    button.pink-btn {
      float: right;
      display: block;
      padding: 0.8rem 3rem;
      border: none;
      border-radius: 50px;
      background: #3a3a3a;
      color: #fff;
      &.complete {
        background: $surveyPrimary;
      }
    }
  }
}

// animations for signups
.signup-animation {
  select {
    width: 100%;
    padding: 1rem 1.5rem;
    border-radius: 300px;
    background: #fff;
    margin-bottom: 3.2rem;
    font-size: 15px;
    height: 54px;
    border-color: #d8dbe0;
  }
  transition: transform 0.4s ease-in;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  input {
    border-radius: 300px !important;
  }

  h4 {
    font-weight: 700;
    color: #000;
    font-size: 16px;
    margin-bottom: 19px;
  }
  &.signup-animation-center {
    transform: translateX(0);
    padding: 1.2rem;
    border: 1px solid #ebebeb;
  }
  &.signup-animation-left {
    padding: 0;
    height: 0;
    overflow: hidden;
    transform: translateX(-120%);
    z-index: -11;
    border: none;
  }
  &.signup-animation-right {
    padding: 0;
    height: 0;
    overflow: hidden;
    transform: translateX(120%);
    border: none;
    z-index: -11;
  }
}
.surbey-history-tools {
  display: flex;
  justify-content: flex-end;
  gap: 2.2rem;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  select {
    padding: 2rem 3.5rem 0 1rem;
    height: 54px;
    font-weight: 500;
    border-radius: 10px;
    background: #fff;
    border-color: rgba(0, 0, 0, 0.2);
    width: 200px;
    font-size: 15px;
  }
  span.select-label {
    position: absolute;
    top: 4px;
    left: 15px;
    color: #8c848f;
    font-size: 11px;
    font-weight: 700;
  }
  h2 {
    color: $surveyPrimary;
    font-size: 56px;
    margin: 0;
    text-align: center;
    font-family: "Lato", sans-serif;
    & + span {
      letter-spacing: 4px;
      font-size: 16px;
      /* line-height: 19.5; */
      color: #444444;
      font-family: "Lato", sans-serif;
    }
  }
}

.setting-support {
  margin: 2.8rem 0;
}

.survey-question-select {
  border: "1px solid #EBEBEB";
  border-radius: 800px;
  width: 248px;
  height: 54px;
  background: #fff;
  padding: 0 0.9rem;
  color: #aaaaaa;
}

.admin-container {
  font-size: 14px;
  min-height: 50vh;
  form {
    h1 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 21px;
      line-height: 26px;
    }
    input,
    select,
    span {
      margin-bottom: 2.7rem;
      //     width: 801px;
      // height: 54px;
      // left: 239px;
      // top: 490px;

      background: #fdfdfd;
      border: 1px solid #ebebeb;
      box-sizing: border-box;
      border-radius: 300px;
    }
    button {
      width: 136px;
      height: 54px;

      border-radius: 300px;
      &.cancel {
        background: #1ee08d;
      }
      &.save {
        background: $surveyPrimary !important;
      }
    }
  }
}
span.trash {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #e8fcf4;
  position: relative;
  border-radius: 50%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
.survey-question-divider {
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  strong {
    color: #1ee08d;
  }
}

.container.user-signup-container .signup-form .controller-outer .form-floating {
  label {
    font-size: 13px;
    padding-left: 1.8rem;
    color: #aaaaaa;
    font-family: "Lato", sans-serif;
    top: 5px;
    opacity: 1;
  }
  input {
    height: 54px;
  }
  .form-control:-webkit-autofill ~ label,
  .form-control:not(:placeholder-shown) ~ label,
  .form-select ~ label {
    transform: scale(0.9) translateY(-1.1rem) translateX(0.25rem) !important;
  }
  .form-control:focus ~ label {
    transform: scale(0.9) translateY(-1.1rem) translateX(0.25rem) !important;
  }
}

.history-user-details {
  margin: 2.2rem 0;
  padding: 1.2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // align-items: center;
  ul {
    .row {
      border: 1px solid;
      border-radius: 600px;
      padding: 1rem 2.2rem;
    }
    li {
      display: flex;
      flex-direction: column;
      span.head {
        color: #aaaaaa;
        text-transform: capitalize;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
      }
      span.value {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
      }
    }
  }
  .survey-details {
    h2 {
      font-family: "Lato", sans-serif;
      font-weight: bold;
      font-size: 48px;
      line-height: 64px;
      color: $surveyPrimary;
    }
    .text-strong {
      font-family: "Lato", sans-serif;
      font-weight: bold;
      font-size: 17px;
      line-height: 21px;
      color: #3a3a3a;
    }
    .text-light {
      font-weight: normal;
      color: #000 !important;
    }
  }
}

.earning-box {
  padding: 22px;
  text-align: center;
  margin: 2rem 0;
  border: 1px solid;
  background: $surveyPrimary;
  color: #fff;
  h3 {
    font-family: "Lato", sans-serif;
    /* font-weight: normal; */
    font-size: 40px;
    line-height: 53px;
    color: #fff;
    font-weight: 400;
  }
  h1 {
    font-family: "Lato", sans-serif;
    font-size: 46px;
    line-height: 56px;
    color: #fff;
    font-weight: 800;
    margin: 0;
  }
  p {
    font-family: "Lato", sans-serif;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  button {
    display: block;
    margin: auto;
    width: 323px;
    height: 54px;
    background: #44225e;
    border-radius: 300px;
    color: #fff;
    border: none;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  a {
    font-family: "Lato", sans-serif;
    /* font-weight: normal; */
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
  }
}

.verify-text {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #6a3494;
  margin: 1.4rem 0;
}
button.verify-btn {
  width: 184px;
  height: 54px;
  left: 868px;
  top: 559px;
  background: #6a3494;
  border-radius: 300px;
  display: block;
  margin: 1.8rem auto;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  border: 1px solid #6a3494;
  transition: all 0.3s ease-in;
  &:hover {
    background: #fff;
    color: #6a3494;
  }
  &.btn-large {
    width: 273px;
  }
}

.verification {
  margin: 1.5rem 0;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  h2 {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    color: #6a3494;
  }
  h3 {
    font-family: "Lato", sans-serif;
    font-size: 32px;
    line-height: 39px;

    text-align: center;
  }
  .verification-code-input-container {
    margin: 3.8rem 0;
    padding: 1.8rem;
    border-bottom: 1px solid;
    input {
      border: none;
      border-bottom: 1px solid #6a3494;
      width: 24px;
      margin: 0 2rem;
      text-align: center;
      color: #6a3494;
      &:disabled {
        background: #fff;
        cursor: not-allowed;
      }
    }
  }
  a {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;

    color: #6a3494;
  }
}

.thankuPage {
  padding: 2.2rem 0;
  margin: 3rem 0;
  img {
    margin: auto;
    display: block;
    width: 165px;
  }
  h1 {
    text-align: center;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 86px;
    margin: 0;
    color: #6a3494;
  }
  p {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    /* identical to box height */

    text-align: center;
  }
}

.payment-methods.container button {
  width: 354px;
  height: 54px;
  background: $surveyPrimary;
  border-radius: 300px;
  margin: 1.2rem auto;
  display: block;
  color: #fff;
  border: none;
  border: 1px solid;
  transition: all 0.2s ease-in;
  &:hover {
    background: #fff;
    color: $surveyPrimary;
  }
}
.payment-methods.container form {
  width: 60%;
  input {
    background: #fdfdfd;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 300px;
    height: 54px;
    font-size: 1.4rem;
    padding: 1.8rem;
    margin: 0;
  }
  .form-floating>.form-control:not(:placeholder-shown)~label{
    opacity: 1;
  }
  label{
    left: 10px;
    top: 2px;
  }
  .error-block {
    text-align: left;
  }
}
.payment-methods {
  .error-block-payment {
    text-align: end;
    color: red;
    margin-bottom: 1.8rem;
    font-weight: 700;
  }
  font-size: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h4 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    /* line-height: 39px; */
    text-align: center;
    color: #000000;
  }
  h2 {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 48px;
    /* line-height: 64px; */
    color: #6a3494;
    margin: 0;
  }
  .card-selector {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    width: 100%;
    margin: 1.2rem 0;
    .card-controls {
      span.control {
        margin-left: 0.8rem;
        input {
          margin: 0 0.4rem;
        }
      }
    }

    h3 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 21px;
      line-height: 26px;
    }
  }
}
.form-floating label {
  color: #aaaaaa;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
}

.row-dragging td {
  // padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.survey-history-card.card {
  img {
    width: 163px;
    margin: 1.2rem;
    vertical-align: top;
    border-radius: 10px;
  }
  span img {
    // margin: 0 1.2rem;
  }
  .card-question strong {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    margin: 1rem 0;
  }
  .card-options {
    margin: 0 1rem;
    input {
      margin: 1rem 0.5rem;
      border-radius: 800px;
      border: 1px solid;
      padding: 0.4rem 0.5rem;
      & + span {
        font-family: "Lato", sans-serif;
        font-size: 17px;
        line-height: 21px;
      }
    }
  }
  .card-answer {
    font-family: "Lato", sans-serif;
    font-size: 17px;
    line-height: 21px;
    margin: 1rem 1rem;
  }
}
.filters {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-right: auto;
  div {
    position: relative;
  }
  select {
    padding: 2rem 3.5rem 0 0;
    padding-top: 2.2rem;
    height: 54px;
    font-weight: 500;
    border-radius: 10px;
    background: #fff;
    font-weight: 700;
    border-color: rgba(0, 0, 0, 0.2);
  }
  span.select-label {
    position: absolute;
    top: 8px;
    left: 5px;
    color: #777;
  }
  input {
    border: none;
    border-bottom: 1px solid;
    padding: 0 0.3rem;
  }
}

.sort-table {
  position: relative;
  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    opacity: 1;
  }
}
.hover-primary:hover a {
  color: #33d791 !important;
}

.history-type {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.survey-detail-item {
  display: flex;
  align-items: center;
  p {
    margin-right: 0.3rem;
  }
  .react-toggle {
    margin: 0 !important;
  }
}

.survey-details-1 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 2rem;
  select {
    padding: 0.5rem;
    font-weight: 500;
    border-radius: 10px;
    background: #fff;
    border-color: rgba(0, 0, 0, 0.2);
    width: 200px;
    font-size: 15px; 
  }
  .survey-verify {
    display: flex;
    align-items: center;
    span {
      margin-right: 1rem;
    }
  }
}
.admin-survey-details {
  h2 {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    color: #6a3494;
  }
  .text-strong {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #3a3a3a;
  }
  .text-light {
    font-weight: normal;
    color: #000 !important;
  }
}
.hr-width{
  margin:0px
}
.hel{
  justify-content: space-between;
}

  

.bar{
fill: #28C587;
}
/* Lighten the axis lines */
#x-axis path,#y-axis line, #y-axis path{
stroke: #666;
}
/* Remove the tick marks on the x-axis */
#x-axis .tick line{
visibility: hidden;
}

text{
fill: #666;
font-size: 12px;
}

#x-axis text {
font-weight: 600;
}
// .table-s{
//   font-size: 10px!important;
// }
.progress{
  margin: 0px
}

.dashboard{
  .card{
    border-radius: 16px;   
    .card-body{
      padding: 1.25rem 3rem;
      .card-title.sub-heading{
        margin: 3rem 0;
        font-size: 24px;
        font-weight: 500;
      }
      &.head-card{
        padding: 3rem;
        .card-title{
          font-size: 20px;
          svg{ color: #bcbcbc; cursor: pointer; }
        }
        .card-text{
          font-size: 32px;
        }
      }
    }
  }
  .bar-graph-legend{
    display: flex;
    justify-content: space-between;
    .btn{
      padding: 5px;
    } 
  }
  table.table {
    thead.thead{
      tr th{
        border-bottom: 2px solid #bcbcbc50;
      }
    }
    tbody{
      tr:last-child{
        th, td{ border: 0; }
      }
    }
  }
  .progress{
    width: 75%;
    border-radius: 8px;
    box-shadow: none;
    .progress-bar{
      border-radius: 8px;
    }
  }
  .app-danger{
    background: #ff4b54;
  }
  .app-success{
    background: #00ac6e;
  }
  .app-primary{
    background: #5994e2;
  }
}
.card-table{
  min-height: 36rem;
}
.card-chart{
  min-height: 51rem;
}
.data-ques{
  font-size: 3rem;
  font-family: "Lato", sans-serif;
}
.data-ans{
  color: rgb(157, 152, 152);
  margin-bottom: 3rem;
  span {
    font-family: "Lato", sans-serif;
  }
  h2{
    font-family: "Lato", sans-serif;
    margin-bottom: 4rem;
  }
}
.answer-table{
  background-color: rgb(225, 225, 225);
  font-family: "Lato", sans-serif;
  th{
    font-size: 16px;
    padding-left: 12px;
  }
}
.answer-table-row{
  color: #3c4b64;
  font-family: "Lato", sans-serif;
  .table-data{
    padding: 4px 9px 4px 12px;
    font-weight: 500;
    vertical-align: middle;
  }
}
.card-box{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.survey-responses{
  font-family: "Lato", sans-serif;
  // position: relative;
  h1 {
    font-size: 35px;
    margin: 42px;
  }
  h2{
    color: #6e7f80;;
    margin: 17px 13px;
    font-weight: 600;
  }
  h3{
    font-size: 4rem;
    margin: 0px 0px 0px 11px;
    color: #36454f;
    font-weight: 100;
  }
  .survey-title{
    text-align: center;
    font-size: 20px;
  }
  .card-box{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding-left: 0px;
    padding-right: 20px;
    // min-height: 132px;
  }
}
.data-trends-msg h2{
  color: #6B787F;
  font-size: 18px;
  font-family: "Lato", sans-serif;
}
