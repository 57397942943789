@import url("https://fonts.googleapis.com/css2?family=Lato:ital@0;1&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

$surveyPrimary: #6a3494;

.navUpperHeader {
  box-sizing: border-box;
  padding: 1rem 0;
  background: linear-gradient(90deg, rgba(106, 52, 148, 0.0984768907563025) 0%, rgba(106, 52, 148, 0.10127801120448177) 100%);
  color: #6A3494;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 12px;
}

.navbarHome {
  font-size: 16px;
}

.headerNav {
  margin: 1.5rem 0;
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    background: linear-gradient(90deg, rgba(106, 52, 148, 0.0984768907563025) 0%, rgba(106, 52, 148, 0.10127801120448177) 100%);
    flex-wrap: wrap;
    padding: 0 1rem;
    margin: 0 auto;
    width: 100%;
  }
}

.navItem {
  margin-right: 1rem;
  font-size: 15px;

  a {
    text-decoration: none;
    color: #000;
    position: relative;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    color: #3A3A3A;

    &.selected {
      color: #33D791 !important;
      text-decoration: none !important;
      font-weight: bold;
    }
  }

  &:not(.signin):not(.signup) a:hover {
    text-decoration: underline;
    color: #000
  }

  label {
    position: relative;

    input {
      border-radius: 4px;
      padding: 0.8rem 0.8rem 0.8rem 3rem;
      font-weight: 100;
      border: 1px solid #ddd;
      color: #000;

      &::placeholder {
        color: #BCBCBC;
      }
    }

    span {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      left: 15px;
      top: 45%;
      transform: translateY(-45%);

      img {
        width: 100%;
      }
    }
  }

  .SigninBtn {
    color: #000;
    padding: 0 1rem;

    &:hover {
      background: #33D791;
      color: #fff !important;
      text-decoration: none !important;
      border-radius: 6px;
      padding: 1rem 1.5rem;
    }
  }
}

.navItem .selected:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 2px;
  background: #33D791;
}

.navBrand {
  // display: flex;
  // align-items: center;
  width: 270px;
  img{
    width: 100%;
    height: 100%;
  }

  h3 {
    font-size: 2.5rem;
    font-family: "Lato", sans-serif;
    font-style: italic;
    font-weight: 700;
  }
}

.signin {
  font-weight: bold;
  font-size: 16px;
  margin-left: 4rem;
  border-radius: 6px;
  padding: 0.6rem 2.4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 1px solid #33D791;

  &:hover {
    background: #fff;
    border: 1px solid #33D791;

    a {
      color: #33D791;
    }
  }
}

.signup {
  font-weight: bold;
  font-size: 16px;
  padding: 0.6rem 2.4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: #33D791;
  border-radius: 6px;
  border: 1px solid #33D791;

  a {
    color: #fff;
  }

  &:hover {
    background: #fff;

    a {
      color: #000;
    }
  }
}

.listMob,
.listMobList {
  display: none !important;
}

.toggle {
  position: relative;
  display: inline-block;
  margin-left: auto;
  margin-right: 50px;
  font-size: 3rem;
  transition: all 0.6s ease-in-out;

  &:before {
    position: absolute;
    content: "|||";
    top: -13px;
    left: 0;
    transform: rotate(90deg) translateX(-50%);
    transition: all 0.5s;
  }

  &.open:before {
    transform: rotate(0deg) translateY(-50%);
    top: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .listUI {
    display: none !important;
  }

  .listMob {
    display: flex !important;
    align-items: center;
  }

  .listMobList {
    display: block !important;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    &.listMobListOpen {
      max-height: 1000px;
    }

    li {
      border-bottom: 1px solid;
      padding: 2rem 0.5rem;
    }
  }

  .navItem label {
    width: 100%;

    input {
      width: inherit;
    }
  }

  .signin {
    width: 130px;
    text-align: center;
    float: left;
    margin-top: 20px;
    margin-left: 20px;
    padding: 1rem !important;
  }

  .signup {
    width: 130px;
    text-align: center;
    float: left;
    margin-top: 20px;
    padding: 1rem !important;
  }
}



// footer

.webFooter {
  margin: 2rem 0 0 0;
  font-size: 16px;

  .footer_links {
    border-top: 1px solid rgba(17, 69, 91, 0.1);

    .footerNav {
      display: flex;
      flex-wrap: wrap;
      margin: 2rem 0;
      padding: 5rem 0;
      justify-content: center;

      a {
        color: #000;
        font-weight: 400;
        font-size: 15px;
        margin-right: 5rem;
        color: #828282;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: #33D791;
          font-weight: 700;
        }
      }
    }

  }

  .footerSocial {
    margin: 2rem 0;

    span {
      display: inline-block;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      gap: 1.2rem;

      i {
        color: #6a3494;
        transition: all 0.2s ease-in-out;

        &:hover {
          font-size: 2rem;
        }
      }
    }
  }

  .footerBar {
    padding: 1.2rem 0rem;
    border-top: 1px solid rgba(17, 69, 91, 0.1);

    .footerBarSpan {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-style: normal;

      span {
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #828282;
      }

      a {
        margin-right: 2rem;
        width: 24px;
        height: 24px;
        display: inline-block;

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .footerContent {
    font-size: 17px;
    font-weight: 300;
  }
}

@media only screen and (max-width: 991px) {
  .navbarHome{
    .headerNav{
      flex-direction: column;
    }
  }
  


  .webFooter {


    .footerContent {
      font-size: 15px;
    }

    .footer_links {
      .footerNav {
        padding: 2.5rem 0;
      }
    }

  }
}

@media only screen and (max-width: 767px) {
  .navbarHome {
    font-size: 16px;
  }

  .headerNav {
    margin: 1rem 0;

    ul {
      margin: 0;
      padding: 0;
    }
  }

  .listMob {
    img {
      width: 200px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .webFooter {
    .footer_links {
      .footerNav {
        padding: 1rem 0;
      }
    }

    .footerBar {
      .footerBarSpan {
        flex-direction: column;
        text-align: center;
      }
    }
  }
}