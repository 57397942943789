// If you want to override variables do it here
@import "variables";
//
//// Import CoreUI styles
//@import "~@coreui/coreui/scss/coreui.scss";
//
//// If you want to add something do it here
@import "custom";

.table {
  width: 100%;
  --bs-table-hover-bg: rgba(220, 220, 220, 0.148) !important;
  border-collapse: collapse;
  th {
    font-size: 14px;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  th.equal-width {
    width: 20.33%;
  }
  td {
    font-size: 14px;
  }
}

.dropdown a {
  font-size: 16px !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  &:hover {
    color: rgb(0, 204, 255) !important;
  }
}
.dropdown-menu {
  inset: 5px 10px auto -21px !important;
  --bs-dropdown-link-active-bg: #abcdff !important;
  --bs-dropdown-link-active-color: #4d4d4d !important;
  text-decoration: none;
  font-weight: 600 !important;
  --bs-dropdown-link-hover-bg: #e6f0ff;
  padding: 3px 10px 5px 10px !important;
}

.pie-chart .card {
  min-height: 467px;
  max-height: auto;
  height: fit-content;
  .fs-1 {
    font-size: clamp(20px, 4vw, 40px) !important;
  }
}
.pie-chart span:after {
  display: inline-block;
  content: "";
  width: 0.8em;
  height: 100%;
  margin-left: 0.4em;
  height: 0.8em;
  border-radius: 0.2em;
  background: currentColor;
}
.apexcharts-legend-text::after,
.apexcharts-legend-marker::after {
  display: none !important;
}
#SvgjsSvg2778 {
  margin: auto !important;
  margin-top: 3em !important;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #ececec !important;
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
  border: none !important;
}
.spring-image {
  height: 200px;
  width: 400px;
  border-radius: 5px;
}
.indicator {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: green;
  display: inline-block;
}
.lightblue {
  background-color: #abcdff;
}
.purple {
  background-color: purple;
}
.blue {
  background-color: #09d0ef;
}

.two-block {
  .card {
    height: 100%;
  }
}

.survey-statistics {
  .survey-btn {
    padding: 5px 20px;
    min-width: 120px;
    border-radius: 5px;
    color: white;
    border: none;
    outline: none;
    font-weight: 700;
    background-color: #ddd;
    transition: all 200ms ease-in-out;
    &.active {
      background-color: #09d0ef;
    }
  }
  .counts {
    .image-wrapper {
      width: 75px;
      height: 75px;
      img {
        object-fit: cover;
      }
    }
  }
}
.control {
  border-radius: 20px;
  border: 1px solid #ddd;
  width: 100%;
  height: 50px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 1rem;
}
.next-button {
  padding: 5px 20px;
  width: fit-content;
  float: right;
  border-radius: 5px;
  background-color: black;
  color: white;
  border: none;
  outline: none;
  font-weight: 700;
}

.app-btn-link {
  color: #1ee08d;
}
.terms label {
  margin: 0;
  font-weight: normal;
}


.contact-container {
  max-width: 700px !important;
}